
<div class="container-login" style="background-color: rgb(201, 228, 239);height: 100vh !important; width: 100 vw !important;">
    <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-12 col-md-5 mx-auto mt-5">
            <div class="card card-pages  shadow-sm my-5 rounded-0 shadow transparent shadow-none">

                <div class="card-body p-5">
                    <div class="text-center m-t-0 m-b-15">
                        <span class="logo logo-admin"> <img src="assets/img/logo-mtfp.png" class="img-fluid mx-auto"
                                alt=""></span>
                    </div>
                    <div class="text-center">
                        <h1 class="h5 text-gray-900 mb-4 ">Plateforme d'enregistrement
                            et de suivi du traitement
                            des requêtes des usagers du MTFP</h1>
                    </div>
                    <div class="alert alert-danger" *ngIf="error">
                        {{error}}
                    </div>
                    <form class="user" #formAuth="ngForm" (ngSubmit)="submit(formAuth.value)">
                        <div class="form-group">
                            <input type="email" class="form-control" ngModel required name="email" id="exampleInputEmail"
                                aria-describedby="emailHelp" placeholder="Email">
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" required ngModel name="password" id="exampleInputPassword"
                                placeholder="Mot de passe">
                        </div>

                        <div class="form-group">
                            <button type="submit" [disabled]="formAuth.invalid || loading" class="btn btn-primary btn-block">Continuer

                                <img *ngIf="loading" src="assets/img/loader.gif" style="width:25px; height:25px; border-radius:100%" />
                            </button>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </div>

</div>