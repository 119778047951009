<!-- Container Fluid-->
<div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h3 class="h3 mb-0 text-gray-800">GESTION DES USAGERS</h3>

    </div>
    <div class="row d-flex">
        <div class="col-md-12">
            <div class="card my-2">
                <!-- Header -->
                <div class="card-header p-4 border-0 pt-5">

                    <div class="card-toolbar d-flex justify-content-between">
                        <ul class="nav nav-pills ml-2 nav-pills-sm nav-dark-75">
                            <li class="nav-item">
                                <span class="mr-2">{{pager.total}} élement(s)</span>
                            </li>
                            <li class="nav-item">
                                <form>
                                    <div class="form-group form-inline">
                                        <input class="form-control form-control-sm " placeholder="Rechercher...." type="text"
                                            [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                                    </div>
                                </form>
                            </li>
                            <li class="nav-item">


                            </li>

                        </ul>
                        <div>
                            <!-- <button *ngIf="user.profil_user.saisie==1 || user.profil_user.admin_sectoriel==1 || user.profil_user.pointfocal==1  || user.profil_user.service==1|| user.profil_user.pointfocalcom==1|| user.profil_user.direction==1" class="btn btn-sm bg-mat-primary text-white" (click)="openAddModal(content)">
                                Ajouter un usager</button> -->
                        </div>
                    </div>
                </div>
                <!-- Body -->
                <div class="card-body pt-3 pb-0">

                    <!--begin::Table-->
                    <div class="table-responsive">

                        <table class="table table-striped datatable  table-head-custom table-vertical-center" id="kt_datatable">
                            <thead>
                                <tr>
                                    <td class="left text-left" colspan="9">
                                        <button (click)="openEditModal(contentU)" *ngIf="user.profil_user.admin_sectoriel==1" class=" mx-1 btn btn-sm bg-mat-primary text-white">
                                            Modifier
                                        </button>
                    
                                        <button *ngIf="user.profil_user.admin_sectoriel==1" (click)="archive()" class="btn mx-1 btn-sm btn-danger">
                                            Supprimer
                                        </button>
                                    
                                        <button (click)="openEditModal(addRequete)" class=" mx-1 btn btn-sm btn-info">
                                            Ajouter Préoccupation
                                        </button>
                                        <button (click)="openRdvModal(newrdv)" class=" mx-1 btn btn-sm btn-info">
                                            Ajouter RDV
                                        </button>
                                      
                                    </td>
                                </tr>  
                                <tr>
                                    <th></th>
                                    <th scope="col">N°</th>
                                    <th scope="col">Département</th>
                                    <th scope="col">Nom et Prénoms</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Téléphone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let el of data ; index as i">
                                    <td>
                                            <input type="radio" [value]="el" ngModel name="get" (change)="checked($event,el)">
                                    </td>
                                    <td>
                                        {{i+1}}
                                    </td>
                                    <td>
                                        {{el.departement==null ? '' :el.departement.libelle}}
                                    </td>
                                    <td>
                                        {{el.nom}} {{el.prenoms}}
                                    </td>
                                    <td>
                                        {{el.email}}
                                    </td>
                                    <td>
                                        {{el.tel}}
                                    </td>

                                   
                                <tr *ngIf="data.length==0">
                                    <td colspan="6" class="text-center bg-gray-100">Aucun elements</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-end p-2">

                            <div *ngIf="pager!=Null">
                                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                                    <li [ngClass]="{disabled:pager.current_page === 1}" class="page-item first-item">
                                        <a routerLink="/listusager" [queryParams]="{ page: 1 }" class="page-link">Début</a>
                                    </li>

                                    <li [ngClass]="{disabled:pager.current_page === 1}" class="page-item previous-item">
                                        <a routerLink="/listusager" [queryParams]="{ page: pager.current_page - 1 }"
                                            class="page-link">Précédent</a>
                                    </li>

                                    <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.current_page === page}"
                                        class="page-item number-item">
                                        <a routerLink="/listusager" [queryParams]="{ page: page }" class="page-link">{{page}}</a>
                                    </li>

                                    <li [ngClass]="{disabled:pager.current_page === pager.last_page}" class="page-item next-item">
                                        <a routerLink="/listusager" [queryParams]="{ page: pager.current_page + 1 }"
                                            class="page-link">Suivant</a>
                                    </li>
                                    <li [ngClass]="{disabled:pager.current_page === pager.last_page}" class="page-item  next-item">
                                        <a routerLink="/listusager" [queryParams]="{ page: pager.last_page }" class="page-link">Fin</a>
                                    </li>
                                    <li class="page-item last-item disabled">
                                        <a class="page-link">{{pager.last_page}} page(s)</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <!--end::Table-->
                </div>


                <ng-template #content let-modal>
                    <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
                        <div class="modal-header bg-mat-primary">
                            <h5 class="modal-title text-white" id="modal-basic-title">Ajouter un usager</h5>
                            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                X
                            </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                                {{error}}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span
                                        aria-hidden="true">×</span>
                                </button>

                            </div>


                            <div class="form-group row">
                                <label class="col-md-4">Nom </label>
                                <div class="col-md-6">
                                    <input class="form-control form-control-sm" #nom="ngModel" maxlength="250" required
                                        name="nom" ngModel>
                                    <span *ngIf="nom.invalid && (nom.dirty || nom.touched)" class="text-danger">
                                        <small *ngIf="nom.errors?.required">
                                            nom est requis
                                        </small>
                                        <small *ngIf="nom.errors.maxlength">
                                            nom ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Prénoms </label>
                                <div class="col-md-6">
                                    <input class="form-control form-control-sm" #prenoms="ngModel" maxlength="250"
                                        required name="prenoms" ngModel>
                                    <span *ngIf="prenoms.invalid && (prenoms.dirty || prenoms.touched)" class="text-danger">
                                        <small *ngIf="prenoms.errors?.required">
                                            prenoms est requis
                                        </small>
                                        <small *ngIf="prenoms.errors.maxlength">
                                            prenoms ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Téléphone </label>
                                <div class="col-md-6">
                                    <input class="form-control form-control-sm" #tel="ngModel" maxlength="250" required
                                        name="tel" ngModel>
                                    <span *ngIf="tel.invalid && (tel.dirty || tel.touched)" class="text-danger">
                                        <small *ngIf="prenoms.errors?.required">
                                            tel est requis
                                        </small>
                                        <small *ngIf="prenoms.errors.maxlength">
                                            tel ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            
                            <div class="form-group row">
                                <label class="col-md-4">Département</label>
                                <div class="col-md-6">
                                    <select class="form-control form-control-sm" #idDepartement="ngModel" maxlength="250" name="idDepartement" ngModel>
                                        <option disabled selected>Choisir</option>
                                        <option [value]="e.id" *ngFor="let e of departements">{{e.libelle}}</option>
                                    </select>
                                    <span *ngIf="idDepartement.invalid && (idDepartement.dirty || idDepartement.touched)"
                                        class="text-danger">
                                        <small *ngIf="idDepartement.errors?.required">
                                            Département est requis
                                        </small>
                                        <small *ngIf="idDepartement.errors.maxlength">
                                            Département ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Email</label>
                                <div class="col-md-6">
                                    <input type="email" class="form-control form-control-sm" #email="ngModel" maxlength="250"
                                        name="email" ngModel>
                                    <!-- <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                        <small *ngIf="email.errors?.required">
                                            email est requis
                                        </small>
                                        <small *ngIf="email.errors.maxlength">
                                            email ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-md-4">Mot de passe *</label>
                                <div class="col-md-6">
                                    <input type="password" class="form-control form-control-sm" #password="ngModel"
                                        maxlength="250"  name="password" ngModel>
                                    <!-- <span *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                                        <small *ngIf="password.errors?.required">
                                            Mot de passe est requis
                                        </small>
                                        <small *ngIf="password.errors.maxlength">
                                            Mot de passe ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Confirmer le mot de passe *</label>
                                <div class="col-md-6">
                                    <input type="password" class="form-control form-control-sm" #conf_password="ngModel"
                                        maxlength="250" name="conf_password" ngModel>
                                    <!-- <span *ngIf="conf_password.invalid && (conf_password.dirty || conf_password.touched)"
                                        class="text-danger">
                                        <small *ngIf="password.errors?.required">
                                            Mot de passe est requis
                                        </small>
                                        <small *ngIf="conf_password.errors.maxlength">
                                            Mot de passe ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid" type="submit">Sauvegarder</button>
                        </div>
                    </form>
                </ng-template>
                <ng-template #contentU let-modal>
                    <form #updateForm="ngForm" (ngSubmit)="edit(updateForm.value)">
                        <div class="modal-header bg-mat-primary">
                            <h4 class="modal-title text-white" id="modal-basic-title">Modifier un usager</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                X
                            </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                                {{error}}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span
                                        aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div class="form-group row"> 
                                <label class="col-md-4">Nom </label>
                                <div class="col-md-6">
                                    <input class="form-control form-control-sm" #nom="ngModel" maxlength="250" required
                                        name="nom" [(ngModel)]="selected_data.nom">
                                    <span *ngIf="nom.invalid && (nom.dirty || nom.touched)" class="text-danger">
                                        <small *ngIf="nom.errors?.required">
                                            nom est requis
                                        </small>
                                        <small *ngIf="nom.errors.maxlength">
                                            nom ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Prénoms </label>
                                <div class="col-md-6">
                                    <input class="form-control form-control-sm" #prenoms="ngModel" maxlength="250"
                                        required name="prenoms" [(ngModel)]="selected_data.prenoms">
                                    <span *ngIf="prenoms.invalid && (prenoms.dirty || prenoms.touched)" class="text-danger">
                                        <small *ngIf="prenoms.errors?.required">
                                            prenoms est requis
                                        </small>
                                        <small *ngIf="prenoms.errors.maxlength">
                                            prenoms ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Téléphone </label>
                                <div class="col-md-6">
                                    <input class="form-control form-control-sm" #tel="ngModel" maxlength="250" required
                                        name="tel" [(ngModel)]="selected_data.tel">
                                    <span *ngIf="tel.invalid && (tel.dirty || tel.touched)" class="text-danger">
                                        <small *ngIf="prenoms.errors?.required">
                                            tel est requis
                                        </small>
                                        <small *ngIf="prenoms.errors.maxlength">
                                            tel ,pas plus de 250 caractères.
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Département *</label>
                                <div class="col-md-6">
                                    <select class="form-control form-control-sm" #idDepartement="ngModel" maxlength="250"
                                        name="idDepartement" [(ngModel)]="selected_data.idDepartement">
                                        <option [value]="e.id" *ngFor="let e of departements">{{e.libelle}}</option>
                                    </select>
                                    <!-- <span *ngIf="idDepartement.invalid && (idDepartement.dirty || idDepartement.touched)"
                                        class="text-danger">
                                        <small *ngIf="idDepartement.errors?.required">
                                            Département est requis
                                        </small>
                                        <small *ngIf="idDepartement.errors.maxlength">
                                            Département ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Email</label>
                                <div class="col-md-6">
                                    <input type="email" class="form-control form-control-sm" #email="ngModel" maxlength="250"
                                         name="email" [(ngModel)]="selected_data.email">
                                    <!-- <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                        <small *ngIf="email.errors?.required">
                                            email est requis
                                        </small>
                                        <small *ngIf="email.errors.maxlength">
                                            email ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>

                            

                            <div class="form-group row">
                                <label class="col-md-4">Mot de passe *</label>
                                <div class="col-md-6">
                                    <input type="password" class="form-control form-control-sm" #password="ngModel"
                                        maxlength="250" name="password" ngModel>
                                    <!-- <span *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                                        <small *ngIf="password.errors?.required">
                                            Mot de passe est requis
                                        </small>
                                        <small *ngIf="password.errors.maxlength">
                                            Mot de passe ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Confirmer le mot de passe *</label>
                                <div class="col-md-6">
                                    <input type="password" class="form-control form-control-sm" #conf_password="ngModel"
                                        maxlength="250" name="conf_password" ngModel>
                                    <!-- <span *ngIf="conf_password.invalid && (conf_password.dirty || conf_password.touched)"
                                        class="text-danger">
                                        <small *ngIf="password.errors?.required">
                                            Mot de passe est requis
                                        </small>
                                        <small *ngIf="conf_password.errors.maxlength">
                                            Mot de passe ,pas plus de 250 caractères.
                                        </small>
                                    </span> -->
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
                        </div>
                    </form>
                </ng-template>

                <ng-template #addRequete let-modal>
                    <form #frmrequeteusager="ngForm" (ngSubmit)="addRequeteusager(frmrequeteusager.value)" class="form-horizontal"
                        novalidate="">

                        <div class="modal-header bg-mat-primary">
                            <h5 class="modal-title text-white" id="modal-basic-title">Ajouter une Préoccupation</h5>
                            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                X
                            </button>
                        </div>
                        <div class="modal-body">
                            <div style="color:red">{{errormessage}}</div>

                            <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                                <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Usager</h4>

                                <div class="form-group row">
                                    <label for="email" class="col-sm-2 control-label">Email</label>

                                    <div class="col-sm-4">
                                        <input type="text" readonly class="form-control form-control-sm has-error" id="email"
                                            name="email" placeholder="" value="{{selected_data.email}}" [(ngModel)]="selected_data.email">

                                    </div>

                                    <label for="nom" class="col-sm-2 control-label">Nom et prénoms</label>

                                    <div class="col-sm-4">
                                        <input readonly="" type="text" class="form-control form-control-sm has-error"
                                            id="nom" name="nom" placeholder="" value="{{selected_data.nom}}"
                                            [(ngModel)]="selected_data.nom">
                                    </div>

                                </div>
<!-- 
                                <div class="form-group row">
                                    <label for="tel" class="col-sm-2 control-label">Téléphone</label>

                                    <div class="col-sm-4">
                                        <input readonly="" type="text" class="form-control form-control-sm has-error"
                                            id="tel" name="tel" placeholder="" value="{{selected_data.tel}}"
                                            [(ngModel)]="selected_data.tel">
                                    </div>

                                    <label for="email" class="col-sm-2 control-label">Département</label>
                                    <div class="col-sm-4">
                                        <select name="idDepartement" readonly="" id="idDepartement" class="form-control form-control-sm form-control-sm"
                                            [(ngModel)]="selected_data.idDepartement" disabled="disabled" >
                                            <option [value]="fc.id" *ngFor="let fc of departements">{{fc.libelle}}</option>
                                        </select>
                                    </div>
                                </div> -->

                            </fieldset>




                            <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">

                                <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Préoccupation</h4>


                                <!--div class="form-group row">
                                        <label for="plainte" class="col-sm-2 control-label">Plainte <br>(oui ou non)
                                        </label>
                                        <div class="col-sm-1">
                                            <input type="checkbox" id="plainte" name="plainte" ngModel>
    
                                        </div>
                                    </div-->
                                <div class="form-group row">

                                    <label for="plainte" class="col-sm-2 control-label">Type :</label>
                                    <div class="col-sm-4">
                                        <select name="plainte" id="plainte" class="form-control form-control-sm"
                                            required [(ngModel)]="selected_type_preoccupation">
                                            <option disabled selected>Choisir</option>
                                            <option value="0">Requete</option>
                                            <option value="1">Plainte</option>
                                            <option value="2">Demande d'information</option>
                                        </select>
                                    </div>
                                    <label for="plainte" class="col-sm-2 control-label">Canal :</label>
                                    <div class="col-sm-4">
                                        <select name="natureRequete" id="natureRequete" class="form-control form-control-sm"
                                            required ngModel>
                                            <option [value]="fc.id" *ngFor="let fc of natures">{{fc.libelle}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="selected_type_preoccupation!=0">

                                    <label for="plainte" class="col-sm-4 control-label">Préoccupation liée à une prestation  :</label>
                                    <div class="col-sm-4">
                                       <label for="">
                                           Oui 
                                            <input type="radio" value="1" [(ngModel)]="link_to_prestation" name="link_to_prestation">
                                       </label>
                                    </div>
                                    <div class="col-sm-4">
                                            <label for="">
                                                Non 
                                                 <input type="radio" value="0" [(ngModel)]="link_to_prestation" name="link_to_prestation">
                                            </label>
                                         </div>
                                </div>
                                
                                <div class="form-group row"  *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">

                                    <label for="idType" class="col-sm-2 control-label">Thématique :</label>
                                    <div class="col-sm-10">
                                        <select name="idType" id="idType" required class="form-control form-control-sm" required
                                            ngModel (change)="chargerPrestation($event)">
                                            <option disabled selected>Choisir</option>
                                            <option [value]="fc.id" *ngFor="let fc of themes">{{fc.libelle}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">
                                    <label for="objet" class="col-sm-2 control-label">Description : </label>
                                    <div class="col-sm-10">
                                        <textarea type="text" disabled="disabled" class="form-control" id="descr"   name="descr" placeholder="Description de la thématique" ngModel  value="{{descrCarr}}"></textarea>
                                    </div>
                                </div>
                                <div class="form-group row"  *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">
                                    <label for="idPrestation" required class="col-sm-2 control-label">Prestation :</label>
                                    <div class="col-sm-10">
                                        <select name="idPrestation" id="idPrestation" class="form-control form-control-sm form-control-sm"
                                            ngModel (change)="openDetailModal($event, detailPresta)">
                                            <option disabled selected>Choisir</option>
                                            <option [value]="fc.id" *ngFor="let fc of services">{{fc.libelle}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="objet" class="col-sm-2 control-label">Objet :</label>

                                    <div class="col-sm-10">
                                        <input type="text" class="form-control form-control-sm has-error" id="objet"
                                            name="objet" placeholder="" ngModel required>
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <label for="msgrequest" class="col-sm-2 control-label">Message :</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control form-control-sm has-error" id="msgrequest" name="msgrequest"
                                            placeholder="Message de la requête" ngModel required></textarea>
                                    </div>
                                </div>




                            </fieldset>


                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary mx-1" id="btn-save"><i class="fas fa-save"></i>&nbsp;
                                Enregistrer</button>

                        </div>
                    </form>

                </ng-template>
                <ng-template #newrdv let-modal>
                    <form #frmrdv="ngForm" (ngSubmit)="saveRdv(frmrdv.value)" class="form-horizontal" novalidate="">
                        <div class="modal-header bg-mat-primary">
                            <h5 class="modal-title text-white" id="modal-basic-title">Prendre un rendez-vous</h5>
                            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                X
                            </button>
                        </div>
                        <div class="modal-body">

                           
                            <div class="form-group row">
                                <label for="dateRdv" class="col-sm-4">Date du prochain RDV</label>
                                <div class="col-sm-7" style="padding-right:0px;">
                                        <input name="dateRdv" required type="date" id="dateRdv" class="form-control form-control-sm" ngModel />

                                </div>
                            </div>
                            <br>


                            <div class="form-group row" style="margin-top: 30px;">
                                <label class="col-sm-4" style="margin-right:15px;">Préoccupation concernée: </label>
            
                                <div class="col-sm-7 search_select_box" style="padding-left:0px;">
                                    <select name="codeRequete" id="codeRequete" class="form-control form-control-sm "
                                        required ngModel (change)="selectRequest($event)">
                                        <option value="0">Aucune</option>
                                        <option [value]="fc.codeRequete" *ngFor="let fc of dataNT">{{fc.codeRequete+' : '+fc.objet}}</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div *ngIf="show_structures" class="form-group row" style="margin-top: 30px;">
                                <label class="col-sm-4" style="margin-right:15px;">Structure </label>
            
                                <div class="col-sm-7" style="padding-left:0px;">
                                    <select name="idStructure" id="idStructure" class="form-control form-control-sm"
                                         ngModel>
                                        <option [value]="fc.id" *ngFor="let fc of structures">{{fc.libelle}}</option>
            
                                    </select>
                                </div>
                            </div>
            
            
                            <div class="form-group row" style="margin-top: 30px;">
            
                                <label for="objet" class="col-sm-4">Objet du RDV</label>
                                <div class="col-sm-7" style="padding-right: 0px;">
                                    <input type="text" class="form-control has-error" id="objet" name="objet"
                                        [(ngModel)]="selected_el_obj" required >
            
                                </div>
                            </div>
        


                            <div class="form-group row" style="margin-top: 30px;">
                                <label class="col-sm-4" style="margin-right:15px;">Créneaux : </label>
                                <div class="col-sm-7" style="padding-left: 0px;">
                                    <select name="idRdvCreneau" id="idRdvCreneau" class="form-control form-control-sm"
                                        required ngModel>
                                        <option [value]="fc.id" *ngFor="let fc of rdvcreneaus">{{fc.heureDebut+' - '+fc.heureFin}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row" style="margin-top: 30px;">
                                <label for="attente" class="col-sm-4">Vos attentes à l'issue de l'entretion :</label>
                                <div class="col-sm-8">
                                    <textarea class="form-control form-control-sm has-error" id="attente" name="attente"
                                        ngModel></textarea>
                                </div>
                                <br><br>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" [disabled]="frmrdv.invalid"><i class="fas fa-save"></i>&nbsp;
                                Enregistrer</button>
                            <br><br>
                        </div>
                    </form>
                </ng-template>

                <ng-template #detailPresta let-modal>
                    <form #frmrdv="ngForm"  class="form-horizontal" novalidate="" *ngFor="let depi of detailpiece">
                        <div class="modal-header bg-mat-primary">
                            <h4 class="modal-title text-white" id="modal-basic-title">Informations sur la prestation</h4>
                            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
                        </div>
                        <div class="modal-body">
                            <label > Prestation : </label>
                            <div class="form-group row" >
                                <label class="col-sm-10" style="padding-right:0px;" > {{ depi.libelle }} </label>
                            </div><br/>
                            <div class="form-group row" >
                                <label class="col-sm-3" > Structure : </label>
                                <div class="col-sm-9" >{{ depi.service_parent.libelle!=null ? depi.service_parent.libelle : 'Aucune structure'}}</div>
                            </div><br/>
                            <div class="form-group row" >
                                <label for="objet" class="col-sm-2">Délai : </label>
                                <div class="col-sm-10" >{{ depi.delai }}</div>
                            </div><br/>
                            <label for="objet" class="col-sm-12">Liste des pièces : </label>
                            <ul class="col-sm-12">
                              <li *ngFor="let piece of depi.listepieces ; index as y " style="margin-bottom: 10px; list-style: none;">{{ y+1}}. {{ piece.libellePiece }} </li>
                            </ul>
                        </div>
                    </form>

                </ng-template>

            </div>
        </div>
    </div>

</div>
<!---Container Fluid-->