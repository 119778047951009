export enum Roles {
    SuperAdmin = 'super-admin',
    Admin = 'admin',
    Restaurant= 'restaurant',
    Hotel= 'hotel',
    TourOperateur= 'tour-operateur',
    AgenceLocationVoiture= 'agence-location-voiture',
    SubAdmin= 'sous-admin',
  
  }
