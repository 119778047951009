<div  class="fixed-sn deep-purple-skin">
  <!-- Navbar -->
   <nav class="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav">
    <!-- SideNav slide-out button -->
    <div class="float-left">
        <a href="#" data-activates="slide-out" class="button-collapse"><i class="fa fa-bars"></i></a>
    </div>
    <!-- Breadcrumb-->
    <div class="breadcrumb-dn">
        <p>
          Plateforme d'enregistrement et de suivi du traitement 
          <br>
          des requêtes des usagers</p>
    </div>
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
        <li class="nav-item">
            <!-- <a class="nav-link"><i class="fa fa-envelope"></i> <span class="clearfix d-none d-sm-inline-block">Contact</span></a> -->
            <button class="btn btn-info rounded-0 my-auto" (click)="openAddModal(edit)">Faire une suggestion</button>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/profil"><i class="fa fa-user mr-2"></i> <span class="clearfix d-none d-sm-inline-block">
              {{user.agent_user?.nomprenoms}} 
                <br>
              {{user.profil_user?.LibelleProfil}} | {{user.agent_user?.structure?.sigle}} |  {{user.entity?.sigle}}</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" style="cursor:pointer" (click)="logout()"><i class="fas fa-sign-out-alt mr-2"></i> <span class="clearfix d-none d-sm-inline-block">Déconnexion</span></a>
        </li>
    </ul>
  </nav>
  <!-- /.Navbar -->

</div>


<ng-template #edit let-modal>

  <form #suggest="ngForm" (ngSubmit)="transmettreComment(suggest.value)">

    <div class="modal-header bg-mat-primary">
      <h5 class="modal-title text-white" id="modal-basic-title">Faire une suggestion</h5>
      <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
        X
      </button>
    </div>
    <div class="modal-body">
      <div style="color:red" class="ng-binding"></div>
      <div class="form-group row">
        <label for="objet" class="col-sm-2 control-label">Objet </label>
        <div class="col-sm-10">
          <input type="text" value="superadminmtfp@gmail.com" name="email" [(ngModel)]="user.email" hidden="" class="ng-pristine ng-untouched ng-valid">
          <input type="text" class="form-control has-error ng-pristine ng-untouched ng-valid" id="objet"
            name="objet" placeholder="Suggestion" ngModel>
        </div>
      </div>
      <div class="form-group row">
        <label for="commentaire" class="col-sm-2 control-label">Suggestion</label>
        <div class="col-sm-10">
          <textarea style="min-height: 100px; resize:none;" class="form-control has-error ng-pristine ng-untouched ng-invalid ng-invalid-required"
            id="commentaire" name="commentaire" ngModel required required="required"></textarea>
        </div>

      </div>
    </div>
    <div class="modal-footer" style="background-color: #fff;">
      <button type="submit" class="btn btn-secondary" id="btn-savetransmit" [disabled]="suggest.invalid"><i class="fas fa-save"></i>&nbsp;
        Envoyer</button>

    </div>
  </form>

</ng-template>