<!-- Container Fluid-->
<div class="container-fluid" id="container-wrapper">
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h3 class="h3 mb-0 text-gray-800">PROFIL</h3>

  </div>
  <div class="row d-flex">
      <div class="col-lg-6 mx-auto">
          <div class="card my-2">
              <!-- Header -->
              <div class="card-header bg-gray-100 p-4 border-0 pt-5">

                <h4>    VOTRE PROFIL</h4>
              </div>
              <!-- Body -->
              <div class="card-body pt-3 pb-0">

                <form #frmAddUser="ngForm" (ngSubmit)="saveUpdateProfil(frmAddUser.value)" class="form-horizontal" novalidate="">
                	

                  <div class="form-group row">
                    <label class="col-sm-4 control-label">Nom </label>
                    <div class="col-sm-7">
                    {{user.agent_user==null ? '' : user.agent_user.nom}}
                    </div>
                  </div>
      
                  <div class="form-group  row">
                    <label class="col-sm-4 control-label">Prénoms </label>
                    <div class="col-sm-7">
                    {{user.agent_user==null ? '' : user.agent_user.prenoms}}
                    </div>
                  </div>
      
                <div class="form-group  row">
                  <label class="col-sm-4 control-label">Profil </label>
                  <div class="col-sm-7">
                    {{user.profil_user.LibelleProfil}}
                   </div>
                </div>
      
                  <div class="form-group  row">
                    <label class="col-sm-4 control-label"> Statut </label>
                    <div class="col-sm-7">
                    {{user.statut==1? 'Actif' : 'Inactif'}}
                  </div>
                  </div>
      
                  <div class="form-group  row">
                    <label class="col-sm-4 control-label">Email </label>
                    <div class="col-sm-7">
                    {{user.email}}
                    </div>
                  </div>
      
                  <div class="form-group  row">
                    <label class="col-sm-4 control-label"> Sexe </label>
                    <div class="col-sm-7">
                      {{user.agent_user==null ? '' : user.agent_user.sexe}}
                  </div>
                  </div>
      
                  <div class="form-group  row">
                    <label class="col-sm-4 control-label"> Profession </label>
                    <div class="col-sm-7">
                      {{user.agent_user==null ? '' : user.agent_user.profession}}
                  </div>
                  </div>
      
                <div class="form-group  row">
                    <label class="col-sm-4 control-label"> Contacts </label>
                    <div class="col-sm-7">
                    {{user.agent_user==null ? '' : user.agent_user.contacts}}
                  </div>
                  </div>
      
                <hr>
                <h4>Modifier vos informations </h4>
                <label>{{errormessage}}</label>
                <div class="form-group  row">
                    <label for="email" class="col-sm-4 control-label">Changer votre Email </label>
                    <div class="col-sm-7">
                    <input type="email" class="form-control" id="email" placeholder="Nouvelle adresse Email " name="newemail" [(ngModel)]="user.email"  />
                    
                  </div>
                  </div>
                  
                 <div class="form-group   row">
                    <label for="newpassword" class="col-sm-4 control-label">Changer votre mot de passe </label>
                    <div class="col-sm-7">
                    <input type="password" class="form-control" id="newpassword" placeholder="Nouveau mot de passe " name="newpassword" ngModel />
                    
                    </div>
                  </div>
      
                <div class="form-group   row">
                    <label for="newpasswordconfirm" class="col-sm-4 control-label">Confirmer le nouveau mot de passe </label>
                    <div class="col-sm-7">
                    <input type="password" class="form-control" id="newpasswordconfirm" placeholder="Confirmer nouveau mot de passe " name="newpasswordconfirm" ngModel  />
                    </div>
                  </div>
      
                <div class="form-group   row">
                    <label for="contacts" class="col-sm-4 control-label"> Changer vos Contacts </label>
                    <div class="col-sm-7">
                    <input type="text" class="form-control" id="contacts" placeholder="Nouveaux contacts " name="newcontacts" ngModel/>
                  </div>
                  </div>
      
                  <div class="form-group" align="center">
                    
                  <button [disabled]="frmAddUser.invalid" type="submit" class="btn btn-primary mx-2" id="btn-save" >
                      <i class="fas fa-save"></i> &nbsp; Modifier votre profil
                  </button>
      
                    <a routerLink="/dashboard"  class="btn btn-danger"><i class="fas fa-undo"></i> Retour </a>
                  </div>
      
            </form>
              </div>


           
          </div>
      </div>
  </div>

</div>
<!---Container Fluid-->