<!-- Container Fluid-->
<div class="container-fluid" id="container-wrapper">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <h3 class="h3 mb-0 text-gray-800">Gestion des rapports d'exploitation</h3>
      
        </div>
        <div class="card">
          <div class="card-body">
            Consulter les statistiques et graphes suivant une période donnée : 
            <div class="row col-md-12">
              <div class="col-md-2">De
                  <div class="form-group">
                    <input class="form-control" type="date" options="options" [(ngModel)]="dated"/>
                  </div>
                </div>
                <div class="col-md-2">A
                  <div class="form-group">
                    <input class="form-control" type="date" options="options" [(ngModel)]="datef"/>
                  </div>
                </div>
                <div class="col-md-6">.
                  <div class="form-group">
                    <button  (click)="print_rapp_periode()" class="btn btn-danger mr-2">Statistiques</button>
                    <button  (click)="print_graphe_periode()" class="btn btn-danger">Graphes</button>
                  </div>
                </div>
              </div>
  
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-12">
            <div class="card my-2">
              <!-- Header -->
              <div class="card-header p-4 border-0 pt-5">
                <div class="card-toolbar d-flex justify-content-between">
                  <ul class="nav nav-pills ml-2 nav-pills-sm nav-dark-75">
                    <li class="nav-item">
                      <span class="mr-2">{{data.length}} élement(s)</span>
                    </li>
                    <li class="nav-item">
                      <form>
                        <div class="form-group form-inline">
                          <input class="form-control form-control-sm " placeholder="Rechercher...." type="text" [(ngModel)]="searchText"
                            name="searchText" (keyup)="search()" />
                        </div>
                      </form>
                    </li>
                    <li class="nav-item">
                    </li>
                  </ul>
                  <div *ngIf="user.idprofil == 2 || user.idprofil == 34">
                    <button class="btn btn-sm bg-mat-primary text-white" (click)="openAddModal(content)"> Ajouter un  commentaire</button>
                  </div>
                </div>
              </div>
              <!-- Body -->
              <div class="card-body pt-3 pb-0">
      
                <!--begin::Table-->
                <div class="table-responsive">
      
                  <table class="table table-striped datatable  table-head-custom table-vertical-center" id="kt_datatable">
                    <thead>
                      <tr *ngIf="user.idprofil == 2 || user.idprofil == 34">
                        <td class="left text-left" colspan="9">
                            <button (click)="openEditModal(contentU)" class=" mx-1 btn btn-sm bg-mat-primary text-white"> Modifier </button>
                            <button (click)="archive()" class="btn mx-1 btn-sm btn-danger"> Supprimer </button>
                        </td>
                    </tr>  
                      <tr>
                        <th></th>
                        <th scope="col">Référence</th>
                        <!-- <th scope="col">Date début</th> -->
                        <th scope="col">à la date du</th>
                        <th scope="col"  class="text-center">Fichier</th>
                        <th scope="col">Commentaires</th>
                        <th scope="col">Télécharger (Rapport)</th>
                        <th scope="col">Consulter  les graphes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <td>
                            <input type="radio" [value]="el" ngModel name="get" (change)="checked($event,el)">
                        </td>
                        <td> {{el.num_enreg}} </td>
                        <!-- <td> {{el.date_debut_com  | date:'dd/MM/yyy'}} </td> -->
                        <td> {{el.date_fin_com  | date:'dd/MM/yyy'}} </td>
                        <td>
                          <span *ngIf="el.fichier_joint">Fichier : <a target="_blank" class="badge badge-primary" (click)="ChangerFile(el.fichier_joint)" href="#">{{el.fichier_joint}}</a></span>
                        </td>
                        <td> {{el.commentaire}} </td>
                        <td class="text-center"> <button (click)="print_rapp(el.date_fin_com)" class="badge btn-danger">Télécharger</button> </td>
                        <td class="text-center"> <button (click)="graphe_rapp(el.date_fin_com)" class="badge btn-danger">Consulter</button> </td>
                      </tr>
                      <tr *ngIf="data.length==0">
                        <td colspan="8" class="text-center bg-gray-100">Aucun élément</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5" [pageSize]="pageSize"
                      [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                    </ngb-pagination>
      
                    <select class="custom-select custom-select-sm float-right" style="width: auto" [(ngModel)]="pageSize">
                      <option selected disabled>Filtrer</option>
                      <option [ngValue]="10">10 éléments par page</option>
                      <option [ngValue]="30">30 éléments par page</option>
                      <option [ngValue]="50">50 éléments par page</option>
                    </select>
                  </div>
                </div>
                <!--end::Table-->
              </div>
      
      
              <ng-template #content let-modal>
                <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
                  <div class="modal-header bg-mat-primary">
                    <h5 class="modal-title text-white" id="modal-basic-title">Ajouter un commentaire</h5>
                    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      X
                    </button>
                  </div>
                  <div class="modal-body">
                    <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                      {{error}}
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4">Date début</label>
                      <div class="col-md-8"> <input name="datedebut" required type="date" id="datedebut" class="form-control form-control-sm" ngModel /> </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-4">Date fin</label>
                      <div class="col-md-8"> 
                        <input name="datefin" required type="date" id="datefin" class="form-control form-control-sm" ngModel /> 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4">Fichier (PDF)</label>
                      <div class="col-md-8"> 
                        <input type="file" class="form-control" required (change)="onFileChange($event)" #file="ngModel" name="fichier" ngModel>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4">Commentaires</label>
                      <div class="col-md-8"> 
                        <textarea class="form-control form-control-sm has-error" required id="comment" rows="4" name="comment" ngModel placeholder="Faites vos commentaires"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid" type="submit">Sauvegarder</button>
                  </div>
                </form>
              </ng-template>

              <ng-template #contentU let-modal>
                <form #updateForm="ngForm" (ngSubmit)="edit(updateForm.value)">
                  <div class="modal-header bg-mat-primary">
                    <h4 class="modal-title text-white" id="modal-basic-title">Modifier un commentaire</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      X
                    </button>
                  </div>
                  <div class="modal-body">
                    <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                      {{error}}
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4">Date début</label>
                      <div class="col-md-8"> <input name="datedebut" required type="date" id="datedebut" class="form-control form-control-sm" [(ngModel)]="selected_data.date_debut_com" ngModel /> </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-4">Date fin</label>
                      <div class="col-md-8"> 
                        <input name="datefin" required type="date" id="datefin" class="form-control form-control-sm" [(ngModel)]="selected_data.date_fin_com" ngModel /> 
                      </div>
                    </div>
                    <!-- <div class="form-group row">
                      <label class="col-md-4">Fichier (PDF)</label>
                      <div class="col-md-8"> 
                        <input type="file" class="form-control" required (change)="onFileChange($event)" #file="ngModel" name="fichier"  ngModel>
                      </div>
                    </div> -->
                    <div class="form-group row">
                      <label class="col-md-4">Commentaires</label>
                      <div class="col-md-8"> 
                        <textarea class="form-control form-control-sm has-error" required id="comment" rows="4" name="comment" ngModel placeholder="Faites vos commentaires" [(ngModel)]="selected_data.commentaire"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
                  </div>
                </form>
              </ng-template>
            </div>
          </div>
        </div>
      
      </div>
      <!---Container Fluid-->