import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noter',
  templateUrl: './noter.component.html',
  styleUrls: ['./noter.component.css']
})
export class NoterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
