import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-rdv-jour',
  templateUrl: './list-rdv-jour.component.html',
  styleUrls: ['./list-rdv-jour.component.css']
})
export class ListRdvJourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
