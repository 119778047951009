<!--begin::Main-->
<div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div class="login login-4 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
        <!--begin::Aside-->
        <div class="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
            <!--begin: Aside Container-->
            <div class="d-flex flex-row-fluid flex-column justify-content-between">
                <!--begin::Aside body-->
                <div class="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
                    <a href="#" class="mb-15 text-center">
                        <img src="assets/media/logos/LogoBeraboominiformatc1.png" class="max-h-100px" alt="" />
                    </a>
                    <!--begin::Signin-->
                    <div class="login-form login-signin">
                        <div class="text-center mb-10 mb-lg-20">
                            <h2 class="font-weight-bold">{{'auth.reset_password' | translate}}</h2>
                            <p class="text-muted ">{{'auth.reset_password_desc' | translate}}</p>
                        </div>
                        <!--begin::Form-->
                        <form class="form" #resetForm="ngForm" (ngSubmit)="submit(resetForm.value)" id="kt_login_signin_form">
                           <div *ngIf="error"  class="alert alert-danger">
                                {{error}}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true"> ×</span> </button>
                           </div>
                            <div class="form-group py-3 m-0">
                                <input class="form-control h-auto border-0 px-0 placeholder-dark-75" type="password" required  #password="ngModel" name="password" ngModel  placeholder="{{'auth.your_password' | translate}}" autocomplete="off" />
                                <span *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                                    <small *ngIf="password.errors?.required">
                                            {{'auth.password' | translate}} {{'global.is_require' | translate}}
                                    </small>
                                     
                                  </span>
                            </div>
                            <div class="form-group py-3 border-top m-0">
                                <input class="form-control h-auto border-0 px-0 placeholder-dark-75" type="password" placeholder="{{'auth.reenter_password' | translate}}" required name="password_c" #password_c="ngModel" ngModel/>
                                <span *ngIf="password_c.invalid && (password_c.dirty || password_c.touched)" class="text-danger">
                                    <small *ngIf="password_c.errors?.required">
                                            {{'auth.conf_password' | translate}} {{'global.is_require' | translate}}
                                    </small>
                                    
                                  </span>
                            </div>
                            <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                              
                            </div>
                            <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                                <div class="my-3 mr-2">
                                   
                                </div>
                                <button type="submit" id="kt_login_signin_submit" [disabled]="resetForm.invalid" class="btn btn-primary font-weight-bold px-9 py-2 mt-3 mb-5  spinner-white spinner-right" [class.spinner]="loading" >{{'auth.continued' | translate}} </button>
                            </div>
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Signin-->
                   
                </div>
                <!--end::Aside body-->
                <!--begin: Aside footer for desktop-->
                <div class="d-flex flex-column-auto justify-content-between mt-15">
                    <a href="https://mataccueil.com" target="_blank"class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">© 2020 mataccueil</a>
                    <div class="d-flex order-1 order-sm-2 my-2">
                        <a href="#" target="_blank" class="text-muted text-hover-primary mx-1">{{'menu.legal_term' | translate}}</a>
                    </div>
                </div>
                <!--end: Aside footer for desktop-->
            </div>
            <!--end: Aside Container-->
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7" style="background-image: url(assets/media/bg/bg-5.jpg);">
            <!--begin::Content body-->
            <div class="d-flex flex-column-fluid flex-lg-center">
                <div class="d-flex flex-column justify-content-center">
                    <h3 class="display-3 font-weight-bold my-7 text-white">Bienvenu Chez mataccueil!</h3>
                    <p class="font-weight-bold font-size-lg text-white opacity-80">Nous vous aidons a preparer votre voyage
                    <br />mataccueil, votre assistant tout au long de votre sejour chez nous.</p>
                </div>
            </div>
            <!--end::Content body-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Login-->
</div>
<!--end::Main-->