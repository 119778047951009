
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-requetes',
  templateUrl: './requetes.component.html',
  styleUrls: ['./requetes.component.css']
})
export class RequetesComponent implements OnInit {

  constructor(
    
  ) { }
  

  ngOnInit(): void {

    
  }


}
