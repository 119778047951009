<!-- Container Fluid-->
<div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h3 class="h3 mb-0 text-gray-800">GUIDE D'UTILISATION</h3>
    </div>
    <div class="row d-flex">
        <div class="col-md-12">
            <div class="card my-2">
                
                <!-- Body -->
                <div class="card-body pt-3 pb-0">

                    <!--begin::Table-->
                    <!-- <div class="table-responsive" *ngFor="let el of data" >
                        <object *ngIf="el.fichier_guide != '' && el.fichier_guide != null" [data]="el.fichier_guide + '#toolbar=0&navpanes=0' | safeUrl" type="application/pdf" width="100%" height="600px"></object>
                        
                        <iframe width="100%" height="600" [attr.src]="el.fichier_guide  | safeUrl" type="application/pdf"></iframe>
                        
                        <embed *ngIf="el.fichier_guide != '' && el.fichier_guide != null" [attr.src]="'http://localhost:8003/rapports/' + el.fichier_guide | safeUrl" type="application/pdf" width="100%" height="600px">
                        <object *ngIf="el.fichier_guide != '' && el.fichier_guide != null" [data]="'https://api.mataccueil.gouv.bj/rapports/' + el.fichier_guide + '#toolbar=0&navpanes=0' | safeUrl" type="application/pdf" width="100%" height="600px"></object>
                            <embed *ngIf="el.fichier_guide != '' && el.fichier_guide != null" [attr.src]="el.fichier_guide + '?output=embed' | safeUrl" type="application/pdf" width="100%" height="700px">

                            <h3 class="text-center" *ngIf="el.fichier_guide == '' || el.fichier_guide == null" >Aucun document n'est relié à votre profil</h3>
                    </div> -->

                    <!--end::Table-->
                </div>


               
            </div>
        </div>
    </div>

</div>
<!---Container Fluid-->