import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-rdv-usager',
  templateUrl: './list-rdv-usager.component.html',
  styleUrls: ['./list-rdv-usager.component.css']
})
export class ListRdvUsagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
